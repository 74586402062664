<template>
    <div>
        <transition name="fade" mode="out-in">
            <auth-loader v-if="isLoading" />
        </transition>

        <div>
            <h1>Member login</h1>

            <login-form
                @loading="isLoading = true"
                @finished="isLoading = false"
            />

            <auth-links>
                <template v-slot:primary>
                    <nuxt-link to="/sign-up">Create new account</nuxt-link>
                </template>

                <template v-slot:secondary>
                    <nuxt-link to="/forgot-password">Forgot password</nuxt-link>
                </template>
            </auth-links>
        </div>
    </div>
</template>

<script>
import LoginForm from '~/patterns/organisms/form/login.vue';
import { AuthLinks, AuthLoader } from '~/dn-modules/auth';

export default {

    components: {
        LoginForm,
        AuthLinks,
        AuthLoader
    },

    beforeRouteEnter: (to, from, next) => {
        next((vm) => {
            vm.$store.commit('ui/activeRouteBeforeLogin', from);
        });
    },

    layout: 'auth',
    middleware: 'auth',
    auth: 'guest',
    transition: 'auth',

    data() {
        return {
            isLoading: false
        };
    },

    head() {
        return this.$seo.getMetaData({
            title: 'Login'
        });
    }
};
</script>
