
<template>
    <div class="login-form">
        <dn-form-field v-if="!isLoading && success" class="dn-form-field--compact">
            <auth-message type="success">
                Logged in succesfully. You'll be forwarded automatically.
            </auth-message>
        </dn-form-field>

        <dn-form-field v-if="failed" class="dn-form-field--compact">
            <auth-message
                v-for="message in errors"
                :key="message.key"
                type="error"
            >
                {{ message.text }}
            </auth-message>
        </dn-form-field>

        <validation-observer
            v-slot="{
                handleSubmit
            }"
        >
            <form
                autocomplete="off"
                method="post"
                class="form"
                @submit.prevent="handleSubmit(onSubmit)"
            >
                <validation-provider v-slot="validator" rules="required|email" slim>
                    <dn-form-field
                        :required="true"
                        :errors="validator.errors"
                        class="dn-form-field--compact"
                    >
                        <dn-input
                            id="user-email"
                            v-model="formData.email"
                            type="email"
                            label="E-mail"
                            :validator="validator"
                            novalidate
                        />
                    </dn-form-field>
                </validation-provider>

                <validation-provider v-slot="validator" rules="required" slim>
                    <dn-form-field
                        :required="true"
                        :errors="validator.errors"
                        class="dn-form-field--compact"
                    >
                        <dn-input
                            id="user-password"
                            v-model="formData.password"
                            label="Password"
                            type="password"
                            :validator="validator"
                            novalidate
                        />
                    </dn-form-field>
                </validation-provider>

                <dn-form-field class="dn-form-field--compact">
                    <dn-button
                        type="submit"
                        class="dn-button--primary dn-button--wide dn-button--large dn-button--move-icon"
                        :disabled="isLoading"
                    >
                        <template v-slot:default>Enter</template>
                        <template v-slot:after>
                            <dn-icon icon="arrow-right" />
                        </template>
                    </dn-button>
                </dn-form-field>
            </form>
        </validation-observer>
    </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { AuthForm, AuthMessage } from '~/dn-modules/auth';

import DnIcon from '~/patterns/atoms/icon/icon.vue';
import DnButton from '~/patterns/atoms/button/button.vue';
import DnInput from '~/patterns/atoms/input/input.vue';
import DnFormField from '~/patterns/molecules/form-field/form-field.vue';

export default {
    components: {
        ValidationObserver,
        ValidationProvider,
        DnIcon,
        DnFormField,
        DnInput,
        DnButton,
        AuthMessage
    },

    mixins: [AuthForm],

    data() {
        return {
            formData: {
                email: '',
                password: ''
            }
        };
    },

    methods: {
        async onSubmit() {
            this.startLoading();

            try {
                await this.$auth.loginWith('laravelGraphql', { data: this.formData });
                // Force-refresh the channels, as they contain some metadata (follows) connected to the current user which would be missing otherwise.
                await this.$store.dispatch('topics/fetchChannels');
                this.setStatusSuccess();
            } catch (errors) {
                this.setErrorState({ errors });
            }

            this.finishLoading();
        }
    }
};
</script>
